import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import styled from 'styled-components'
import Metadata from '../../constants/metadata'
import { GrowButton } from '../button'
import { Heading4 } from '../text'
import { LogoWrapper, Logo } from '../logo'
import { Color, FontSize, Spacing } from '../../constants/variables'
import { ButtonStyle } from '../../constants/design'
import { Link } from "gatsby"

class Footer extends React.Component {

    render() {
        const Section = styled.section`
            color: ${Color.white};
        `
        const FooterMain = styled.div`
            background-color: ${Color.black};
            padding: ${Spacing.four};
            padding-bottom: ${Spacing.two};
        `
        const Copyright = styled.div`
            text-align: center;
            font-size: ${FontSize.smallest};
            margin: 0 auto;
            span {
                color: ${Color.light};
            }
        `
        const NavigationLinks = styled.ul`
            list-style: none;
            padding-left: 0;
        `
        return (
            <FooterMain>
                <Section id="work">
                    <Container>
                        <Row>
                            <Col md={4} style={{ marginBottom: Spacing.two }}>
                                <LogoWrapper className="logo">
                                    <Link to="/">
                                        <Logo src={Metadata.Static.logoURLSmall} alt="logo" />
                                    </Link>
                                </LogoWrapper>
                            </Col>
                            <Col md={8} style={{ marginBottom: Spacing.two }}>
                                {Metadata.Footer.text}
                            </Col>
                            <Col md={4} style={{ marginBottom: Spacing.two }}>
                                <Heading4 style={{ marginBottom: Spacing.two }}>
                                    {Metadata.Footer.callToAction}
                                </Heading4>
                                <GrowButton style={{ marginBottom: Spacing.two }}>
                                    <Link to="/contact">
                                        <span>{Metadata.Contact.formTitle}</span>
                                        <span style={ButtonStyle.growButtonLargeArrow}>→</span>
                                    </Link>
                                </GrowButton>
                                <a href={`mailto://${Metadata.Static.contactEmail}`}>{Metadata.Static.contactEmail}</a>
                            </Col>
                            <Col md={4}>
                                <Heading4>{Metadata.Footer.navigationHeader}</Heading4>
                                <NavigationLinks>
                                    {Metadata.Static.footerLinkList()}
                                </NavigationLinks>
                            </Col>
                            <Col md={8}></Col>
                        </Row>
                        <Row style={{ marginTop: Spacing.two }}>
                            <Col md={12}>
                                <Copyright>
                                    <p>{Metadata.Static.copyright()}</p>
                                </Copyright>
                            </Col>
                        </Row>
                    </Container>
                </Section>
            </FooterMain >
        )
    }
}
export default Footer