import styled from 'styled-components'

const LogoWrapper = styled.div`
  flex: 0 0 20%;
  max-width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 999999;
  @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: center;
  }
`

const Logo = styled.img`height: 40px;`

export {
  LogoWrapper,
  Logo
}