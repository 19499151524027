import styled from 'styled-components'
import { Theme } from '../../constants/variables'

export const NavigationItem = styled.button`
    background: none;
    border: none;
    color: ${Theme.color.white};
    text-transform: capitalize;
    font-weight: ${Theme.fontWeight.heavy};
    margin: 10px 5px;
    margin-right: 20px;
    transition: .5s;
    &:hover {
        color: ${Theme.color.primary};
    }
    &:focus {
        outline: none;
    }
    @media (max-width: 767px) {
        margin: 20px 10px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    @media (min-width: 501px) and (max-width: 1023px) {
        font-size: ${Theme.fontSize.smaller};
    }
`