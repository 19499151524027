import React from 'react'
import PageRevealer from 'components/page-revealer'
import NavigationBar from 'components/navigation-bar'
import Footer from 'components/footer'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'scss/abstract.scss'

class Layout extends React.Component {
  componentDidUpdate() {
    window.location.reload(false);
  }

  render() {
    const { children } = this.props
    return (
      <div id="main">
        <NavigationBar scroll={false} sections={this.props.sections} />
        {this.props.useReveal ? (<PageRevealer />) : ('')}
        <div>
          {children}
        </div>
        <Footer />
      </div >
    )
  }
}

export default Layout
